import React from "react";
import Card1 from "../../../assets/images/softwarecrd1.png"
import Card2 from "../../../assets/images/Softwarecrd2.png"
import Card3 from "../../../assets/images/softwareCard3.png"

export default function SoftwareProviderCards() {
    const cardData = [
        {
            text: "Search TIN FC/PAN Centre near your location having Biometric based facility to authenticate Aadhaar while acceptance of PAN application",
            href: "./downloads/List_of_TINFC_PAN_Centre-Biometric_11122024.xlsx",
            desktopbg: Card1,
            mobileBg: "",
            circleBg: "#3091D0",
        },
        {
            text: "Designated centres accepting Online PAN documents",
            href: "https://www.protean-tinpan.com/downloads/List_Of_Centres_For_Submission_Of_Online_PAN_Documents.xlsx",
            desktopbg: Card2,
            mobileBg: "",
            circleBg: "#EE7F25",
        },
        {
            text: "Search for PAN Service Agencies Centre near your location for accepting PAN application.",
            href: "https://www.protean-tinpan.com/downloads/PSA-Centre-list.xlsx",
            desktopbg: Card3,
            mobileBg: "",
            circleBg: "#FFCE1B",
        },

    ];
    return (
        <section className="relative text-black py-4 px-4 lg:px-[70px]">
            <div className="flex flex-wrap gap-[1.6rem]">
                {cardData.map((card, index) => (
                    <div
                        className="flex justify-between  bg-no-repeat bg-cover min-w-full lg:min-w-[412px] min-h-[203px]"
                        style={{
                            backgroundImage: `url(${card.desktopbg
                                })`,
                        }}
                    >
                        <a href={card.href} className="cursor-pointer w-full md:w-[90%]">
                            <div class="flex justify-between  px-4  py-[8px]   ">
                                <p class="  max-w-[252px] text-white text-[23px] font-semibold">
                                    {card.text}
                                </p>

                                <div
                                    className={`rounded-full w-[40px] h-[40px] flex justify-center items-center `}
                                    style={{ backgroundColor: card.circleBg }}
                                >
                                    <svg
                                        class="text-[#fefefe]"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.9495 3.05078L3.04997 12.9503"
                                            stroke="#fefefe"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M12.9495 8.70764V3.05078"
                                            stroke="#fefefe"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7.29291 3.05108H12.9498"
                                            stroke="#fefefe"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
}
