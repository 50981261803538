import React, { useEffect, useState } from "react";
import { RefreshSvg } from "../../../assets/svgs";
import Recaptcha from "../../../components/ReCaptcha";

const CustomerCareQuery = () => {
  const [captchaCode, setCaptchaCode] = useState("");
  const [enterCaptchaCode, setEnterCaptchaCode] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    flat: "",
    building: "",
    road: "",
    locality: "",
    city: "",
    state: "",
    pin: "",
    phone: "",
    email: "",
    pan: "",
    complaintArea: "",
    complaint: "",
    consent: null,
  });

  const generateCaptcha = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  useEffect(() => {
    setCaptchaCode(generateCaptcha());
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const refreshCaptcha = () => {
    setCaptchaCode(generateCaptcha());
  };

  const handleConsentChange = (e) => {
    const { name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      consent: name === "consentYes" ? true : false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    refreshCaptcha(); // Refresh the captcha

    const feedbackContent = `
      <p>Dear Sir / Madam, <br/><br/>
      This is with reference to the feedback in the area <b>'${formData.complaintArea}'</b>, the feedback as well as the detail of applicant is as follows:-<br/><br/>
      Contact details as follows:</p>
      <table width='100%' cellspacing='0' cellpadding='0' border='2'>
          <tbody>
              <tr>
                  <th width='15px' align='center'>S.No.</th>
                  <th width='200px'>Particulars</th>
                  <th width='200px'>Detail of applicant</th>
              </tr>
              <tr><td align='center'>1</td><td>Name</td><td>${formData.fullName}</td></tr>
              <tr><td align='center'>2</td><td>Flat/Door/Block No</td><td>${formData.flat}</td></tr>
              <tr><td align='center'>3</td><td>Name of Building/Premises/Village</td><td>${formData.building}</td></tr>
              <tr><td align='center'>4</td><td>Road/Street/Lane</td><td>${formData.road}</td></tr>
              <tr><td align='center'>5</td><td>Area/Locality</td><td>${formData.locality}</td></tr>
              <tr><td align='center'>6</td><td>Town/City/District</td><td>${formData.city}</td></tr>
              <tr><td align='center'>7</td><td>State/Union Territory</td><td>${formData.state}</td></tr>
              <tr><td align='center'>8</td><td>Pin Code</td><td>${formData.pin}</td></tr>
              <tr><td align='center'>9</td><td>Telephone</td><td>${formData.phone}</td></tr>
              <tr><td align='center'>10</td><td>Email ID</td><td>${formData.email}</td></tr>
          </tbody>
      </table>
      <br/>
      <p>${formData.complaintArea} provided <br/><br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${formData.complaint}<br/><br/>
      Regards,<br/>
      <b>${formData.fullName}</b>
    `;

    console.log("Form submitted:", feedbackContent);
  };

  
  return (
    <form onSubmit={handleSubmit} className="lg:p-6 bg-white rounded-lg lg:max-w-3xl">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="md:col-span-2">
          <label className="block text-sm font-medium">
            Full Name<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Flat/Door/Block No.</label>
          <input
            type="text"
            name="flat"
            value={formData.flat}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Name of Building/Premises/Village</label>
          <input
            type="text"
            name="building"
            value={formData.building}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Road/Street/Lane</label>
          <input
            type="text"
            name="road"
            value={formData.road}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Area/Locality</label>
          <input
            type="text"
            name="locality"
            value={formData.locality}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Town/City/District</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">
            Choose State/Union Territory<span className="text-red-500">*</span>
          </label>
          <select
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
            required
          >
            <option value="">Select State</option>
            <option value="ANDAMAN &amp; NICOBAR ISLANDS">ANDAMAN &amp; NICOBAR ISLANDS</option>
            <option value="ANDHRA PRADESH">ANDHRA PRADESH</option>
            <option value="ARUNACHAL PRADESH">ARUNACHAL PRADESH</option>
            <option value="ASSAM">ASSAM</option>
            <option value="BIHAR">BIHAR</option>
            <option value="CHHATTISGARH">CHHATTISGARH</option>
            <option value="DADRA &amp; NAGAR HAVELI">DADRA &amp; NAGAR HAVELI</option>
            <option value="DELHI">DELHI</option>
            <option value="GOA">GOA</option>
            <option value="GUJARAT">GUJARAT</option>
            <option value="HARYANA">HARYANA</option>
            <option value="HIMACHAL PRADESH">HIMACHAL PRADESH</option>
            <option value="JAMMU &amp; KASHMIR">JAMMU &amp; KASHMIR</option>
            <option value="JHARKHAND">JHARKHAND</option>
            <option value="KARNATAKA">KARNATAKA</option>
            <option value="KERALA">KERALA</option>
            <option value="MADHYA PRADESH">MADHYA PRADESH</option>
            <option value="MAHARASHTRA">MAHARASHTRA</option>
            <option value="MANIPUR">MANIPUR</option>
            <option value="MIZORAM">MIZORAM</option>
            <option value="ODISHA">ODISHA</option>
            <option value="PONDICHERRY">PONDICHERRY</option>
            <option value="PUNJAB">PUNJAB</option>
            <option value="RAJASTHAN">RAJASTHAN</option>
            <option value="TAMIL NADU">TAMIL NADU</option>
            <option value="TELANGANA">TELANGANA</option>
            <option value="TRIPURA">TRIPURA</option>
            <option value="UTTAR PRADESH">UTTAR PRADESH</option>
            <option value="UTTARAKHAND">UTTARAKHAND</option>
            <option value="WEST BENGAL">WEST BENGAL</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium">PIN</label>
          <input
            type="text"
            name="pin"
            value={formData.pin}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Telephone No.</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium">Email ID</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">PAN (Optional)</label>
          <input
            type="text"
            name="pan"
            value={formData.pan}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium">
            Area in which you have Query (Select any one){" "}
          </label>
          <select
            name="complaintArea"
            value={formData.complaintArea}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          >
            <option value="Annual Information Return (AIR)">Annual Information Return
              (AIR)
            </option>
            <option value="AIN Registration">AIN Registration</option>
            <option value="Book Identification Numer (BIN) View">Book Identification
              Numer (BIN) View
            </option>
            <option value="Challan Status Enquiry">Challan Status Enquiry</option>
            <option value="Deductor's Manual">Deductor's Manual</option>
            <option value="e-Return Intermediary">e-Return Intermediary</option>
            <option value="File Validation Utility">File Validation Utility</option>
            <option value="Form 24G">Form 24G</option>
            <option value="NSDL's Return Preparation Utility(RPU)">NSDL's Return
              Preparation Utility(RPU)
            </option>
            <option value="Other RPU/FVU">Other RPU / FVU</option>
            <option value="PAN Application">PAN Application</option>
            <option value="Quarterly Statement Status">Quarterly Statement Status
            </option>
            <option value="Status of Tax Refund">Status of Tax Refund</option>
            <option value="TAN Application">TAN Application</option>
            <option value="TIN Facilitation Centers/PAN Centers">TIN Facilitation
              Centers/PAN Centers
            </option>
            <option value="Online PAN Verification">Online PAN Verification facility</option>
          </select>
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium">
            Describe your Query
          </label>
          <textarea
            name="complaint"
            value={formData.complaint}
            onChange={handleChange}
            rows="4"
            className="mt-1 p-2 w-full border border-gray-300 rounded"
          ></textarea>
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-sm font-medium">Consent Statement</label>

        <div className="flex flex-col  gap-2 mt-1">
          <p className="text-sm">
            We seek your consent to use details provided by you to enhance the
            experience and to keep you updated at all times with the latest
            newsletters, services offered, as well as new features and services
            launched by Protean eGov Technologies Limited. Please select "Yes"
            to receive promotional email or SMS from Protean.
          </p>

          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="consentYes"
                checked={formData.consent === true}
                onChange={handleConsentChange}
                className="form-checkbox"
              />
              <span className="ml-2 text-sm">Yes</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="consentNo"
                checked={formData.consent === false}
                onChange={handleConsentChange}
                className="form-checkbox"
              />
              <span className="ml-2 text-sm">No</span>
            </label>
          </div>
        </div>
      </div>

      {/* <div className="flex mt-4 flex-wrap gap-10 w-full">
        <div className=" w-full lg:min-w-[307px] lg:max-w-[307px]">
          <label className="block mb-2">Captcha Code</label>
          <div className="flex justify-between border">
            <p className="py-2 px-4 bg-gray-300">{captchaCode}</p>
            <button onClick={refreshCaptcha} type="button" className="flex justify-center items-center">
              <RefreshSvg />
            </button>
            <Recaptcha onChange={setCaptchaCode} />
          </div>
        </div>
        <div className="lg:min-w-[307px] w-full lg:max-w-[307px]">
          <label className="block mb-2">Enter Captcha Code</label>
          <input
            type="text"
            value={enterCaptchaCode}
            onChange={(e) => setEnterCaptchaCode(e.target.value)}
            className="p-2 w-full border lg:min-w-[307px] lg:max-w-[307px] rounded"
            placeholder="Enter code"
          />
        </div>
      </div> */}

      <div className="mt-6 flex justify-end gap-4">
        <button
          type="button"
          className="p-2 px-4 text-black  rounded"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="p-2 px-4 bg-[#6F368B] text-white rounded"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default CustomerCareQuery;